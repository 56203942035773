.page404 {
  display: flex;
  flex-direction: column;
  margin: 24px 0 37px;
}

@media (min-width: 768px) {
  .page404 {
    margin: 60px 0;
    flex-direction: row;
  }
}

.page404__image {
  margin: 0 auto 24px;
}

@media (min-width: 768px) {
  .page404__image {
    margin: 0 34px 0 0;
  }
}

.page404__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .page404__content {
    align-items: flex-start;
  }
}

.page-title--page404 {
  margin: 0 0 8px;
}

.page404__text {
  margin: 0 0 8px;
  font-size: 12px;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .page404__text {
    margin-bottom: 16px;
  }
}

@media (min-width: 1080px) {
  .page404__text {
    font-size: 14px;
  }
}

.page404__link-home {
  color: #fff;
  background: #bc191d url(/resource_1/images/lines-right.svg) right center no-repeat;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #bc191d;
  width: 133px;
  height: 38px;
  border-radius: 10px;
  text-decoration: none;
  padding: 0 18px;
  transition: background-color .3s;
}

.page404__link-home:hover {
  background-color: #af161a;
}
